import styled from "styled-components";

export const Wrapper = styled.div`
  text-align: center;
  color: red;
`;

export const SyntaxOkay = styled.h2`
  color: grey;
  display: inline;
`;

export const SyntaxBad = styled.h2`
  color: red;
  display: inline;
`;

export const ErrorMessageText = styled.p`
  color: grey;
`;
