import styled from "styled-components";
import { Modal } from "react-bootstrap";

export const BodyText = styled.p`
  color: black !important;
  margin: 4px;
`;

export const UL = styled.ul`
  list-style: initial;
  margin: initial;
  padding: 0 0 0 40px;
`;

export const LI = styled.li`
  display: list-item;
  color: black;
`;

export const StyledModalTitle = styled(Modal.Title)`
  color: black !important;
  margin: 4px;
`;
