import styled from "styled-components";
import { Table } from "react-bootstrap";

export const StyledTable = styled(Table)`
  margin: 0 auto;
  text-align: center;
  font-size: 1.5rem;
  max-width: 90%;
  border: 0;
`;
