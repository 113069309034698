import styled from "styled-components";
import { InputGroup, FormControl } from "react-bootstrap";

export const Wrapper = styled.div``;

export const StyledInputGroup = styled(InputGroup)``;

export const StyledFormControl = styled(FormControl)`
  font-size: 2.75rem !important;
  color: white !important;
  margin: 0 auto !important;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  max-width: 777px;
  text-align: center;
  background-color: transparent !important;
  outline: 0px !important;
  -webkit-appearance: none;
  box-shadow: none !important;
`;
